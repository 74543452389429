import './KnowledgeBase.scss'

import { graphql } from 'gatsby'
import React, { FunctionComponent } from 'react'
import EasySoftwareSearch from 'Root/Shared/components/EasySoftwareSearch/EasySoftwareSearch'
import WidgetBuilder, {
  WidgetData,
} from 'Shared/components/builders/WidgetBuilder'
import EasySoftwareBreadcrumbs, {
  BreadcrumbItem,
} from 'Shared/components/EasySoftwareBreadcrumbs/EasySoftwareBreadcrumbs'
import EasySoftwareSideCardNavigation from 'Shared/components/EasySoftwareSideCard/EasySoftwareSideCardNavigation'
import PuxColumns, {
  PuxMainColumn,
  PuxSideColumn,
} from 'Shared/components/PuxColumns/PuxColumns'
import { localizedPathType } from 'Shared/components/PuxLanguageSelector/PuxLanguageSelector'
import { KnowledgeBaseMenuItemType } from 'Shared/utils/getKnowledgeBaseData'
import { getLocalizedUrl } from 'Shared/utils/localeURL'
import PuxMetaTags, { PuxMetaTagsType } from 'Shared/utils/PuxMetaTags'

import Layout from '../../components/core/Layout'

export const query = graphql`
  query($pagePath: String = "") {
    orchard {
      easySoftwareKnowledgeBase(first: 1, where: { path: $pagePath }) {
        displayText
        localization {
          localizations {
            ... on Orchard_EasySoftwareKnowledgeBase {
              path
              published
              localization {
                culture
              }
            }
          }
        }
      }
    }
  }
`

interface PageType {
  displayText: string
  localization: {
    localizations: localizedPathType[]
  }
}

interface KnowledgeBaseType {
  data: {
    orchard: {
      easySoftwareKnowledgeBase: [PageType]
    }
  }
  pageContext: {
    breadcrumbs: [BreadcrumbItem]
    metaTags: PuxMetaTagsType
    knowledgeBaseData: KnowledgeBaseMenuItemType[]
    sideCardWidgets: any
  }
}

const KnowledgeBase: FunctionComponent<KnowledgeBaseType> = ({
  data,
  pageContext,
}) => {
  const breadcrumbsData = pageContext.breadcrumbs
  return (
    <Layout
      localizedPath={
        data.orchard.easySoftwareKnowledgeBase[0].localization?.localizations
      }
    >
      <EasySoftwareBreadcrumbs items={breadcrumbsData} />
      <PuxMetaTags {...pageContext.metaTags} />
      <div className='pux-container knowledge-base'>
        <PuxColumns>
          <PuxMainColumn>
            <h1>{data.orchard.easySoftwareKnowledgeBase[0].displayText}</h1>
            <ol className='knowledge-base-category'>
              {pageContext.knowledgeBaseData.map((item) => (
                <li>
                  <h2>
                    <a href={getLocalizedUrl(item.path)}>
                      {item.displayTextWithoutNumber}
                    </a>
                  </h2>
                  {item.menuItemsList.map((subItem) => (
                    <h3>
                      <a href={getLocalizedUrl(subItem.path)}>
                        {subItem.displayText}
                      </a>
                    </h3>
                  ))}
                </li>
              ))}
            </ol>
          </PuxMainColumn>
          <PuxSideColumn>
            <div className='sidebar-sticky'>
              <EasySoftwareSearch
                searchType='knowledgebase'
                setLanguageDialogOpen={() => true}
              />
              <EasySoftwareSideCardNavigation
                type='knowledgeBase'
                sideCardNavigationLinks={pageContext.knowledgeBaseData}
              />
              <WidgetBuilder widgetBuilderData={pageContext.sideCardWidgets} />
            </div>
          </PuxSideColumn>
        </PuxColumns>
      </div>
    </Layout>
  )
}

export default KnowledgeBase
